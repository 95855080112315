import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import AnimatedNumber from 'react-animated-number';
import Web3 from 'web3';


import DKUtil from '../../utils/DKUtil'
import KUSDTUtil from '../../utils/KUSDTUtil'

import abiAuction from './abiauction.json';
import abiSold from './abisoldnft.json';

import './styles.css';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const GameTable = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

//  reveal-from-bottom
const outerClasses = classNames(
  'total section center-content-mobile',
  topOuterDivider && 'has-top-divider',
  bottomOuterDivider && 'has-bottom-divider',
  hasBgColor && 'has-bg-color',
  invertColor && 'invert-color',
  className
);

const innerClasses = classNames(
  'total-inner',
  topDivider && 'has-top-divider',
  bottomDivider && 'has-bottom-divider',
  split && 'total-split'
);  


const [data, setData] = useState("");
const [mul, setMul] = useState(0);
// const [muldefi, setMulDefi] = useState(27);
const [total, setTotal] = useState("");
// const [defi, setDefi] = useState(0);
const [nftsum, setNFTSum] = useState(0);

const [dkPrice, setDKPrice] = useState(0);
const [dkAmount, setDKAmount] = useState(0);
const [kusdtAmount, setKusdtAmount] = useState(0);

let sum = 0;

const fetchData = async () => {


  let gameapi = "https://api.diamon.finance/TVL5"
  // let gameapi = 'http://localhost:8888/temp/diamon/game-data.php'
  try {
    const response = await fetch(gameapi)
    const res = await response.json()
    
    if (res != '') {
      console.log("Res ", res)
      if (res.data)
        setData(res.data)
      if (res.total)
        setTotal(res.total)

    }
  } catch (error) {
    console.error('Unable to fetch price data:', error)
  }
}


const auctionValue = async () => {
  const web3Provider = new Web3.providers.HttpProvider('https://rpc.bitkubchain.io');
  const web3 = new Web3(web3Provider);
  // const web3 = new Web3(window.web3.currentProvider);
  const address = "0x2599C1d23C0AF6D14B141e8FDbbEd13e81E61427";
  const a = new web3.eth.Contract(abiAuction, address);
  const b = await a.methods.allAuctionEndedCount().call();


  const address2 = "0x874987257374cAE9E620988FdbEEa2bBBf757cA9";
  const c = new web3.eth.Contract(abiSold, address2);
  const d = await c.methods.allSoldListingCount().call();


  let s = Number(b) + Number(d);
  setNFTSum(s);

};

const callDKPrice = async () => {
  const DiamonLP = "0x9F718A7649d4a3423e56B677dc1Ee9955127a29e";
  const dk = DKUtil.methods.balanceOf(DiamonLP).call()
  dk.then(function(data) {
    // console.log("DK AMount : ", data)
    setDKAmount(data)
    if (kusdtAmount > 0)  {
      setDKPrice((kusdtAmount/data))
    }
  })

const kusdt = KUSDTUtil.methods.balanceOf(DiamonLP).call()
  kusdt.then(function(data) {
    // console.log("kusdt AMount : ", data)
    setKusdtAmount(data)
    if (dkAmount > 0)  {
      setDKPrice((data/dkAmount))
    }
  })
}

// useEffect(() => {
//   auctionValue()
//   fetchData()

// }, [setData]);

useEffect(() => {

  let cancel = false;
  fetchData()
  auctionValue()
  const interval = setInterval(() => {
    // console.log("Cancel ", cancel)
    if (cancel) return;
    fetchData()
    auctionValue();
  }, 10000);
  // console.log("Interval");
  return () => {
    cancel = true;
    console.log("Clear Table Interval");
    clearInterval(interval);
    // window.location.reload(false);
  };
}, []);

  function currencyFormat(num) {
     return '฿' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  function getValue(d)  {
    if (d !== "") {
      let v = Number(d);
      return (
        <AnimatedNumber value={v} duration={300} formatValue={(n) => currencyFormat(n)} />
      )
    }
  }

  function amountFormat(num) {
    return  num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  function getAmount(d)  {
    if (d !== "") {
      let v = Number(d);
      return (
        <AnimatedNumber value={v} duration={300} formatValue={(n) => amountFormat(n)} />
      )
    }
  }
 
  function calculateMul(code, p) {
    let s = p;
    if (code == 'MMV')
      s = 1.5;
    else if (code == 'DK')
      s = 13.5
    else if (code == 'FAN')
      s = 0.1
    else
      return <span>-</span>

    let newMul = p / s;

    return <span>{newMul.toFixed(1)}x</span>
  }

  // function calculateDKMul(p) {
  //   let newMul = p / 13.5;
  //   return <span>{newMul.toFixed(1)}x</span>
  // }

  function calculateMulDefi(code, p) {
    let s = p;
    if (code == 'YES')
      s = 27
    else 
      return <span>-</span>

    let newMul = p / s;
    return <span>{newMul.toFixed(2)}x</span>
  }

  console.log("Data for map ", data)

  return (

    <section
      {...props}
    >
      <div className="container">
        <div className={innerClasses} >
          <table className="gfg">
          <tbody>
          	<tr>
          		<td className="text-value" style={{textAlign: "left"}}>GameFi Projects  </td>
          		<td>   </td>
          		<td style={{width:"100px"}}>Price</td>
          		<td style={{width:"120px"}}>TVL</td>
          		<td style={{width:"120px"}}>Trading</td>
          		<td style={{width:"130px"}}>Market Cap </td>
              <td></td>
          	</tr>

            
            { data!=="" && Array.isArray(data) && data.map((row, index)=>  (
              row.type === 'GameFi' &&
              row.active === '1' &&
          	  <tr className="table-row" key={index}>
                
                  <td style={{padding: "0"}}><img src={`/game/${row.code}.png`} alt="Game Defi" width="152px" /></td>
                  <td className="text-value align-left"> {row !== "" && row.name} {row !=="" && row.active==='1' && <span className="badge">{calculateMul(row.code, row.price)}</span>}</td>
              		<td className="text-value"> {row !== "" && getValue(row.price)}{row!=="" && row.priceunit} {row === "" && "0"} </td>
              		<td className="text-value"> {row !== "" && getValue(row.tvl)}{row!=="" && row.tvlunit} {row === "" && "0"}</td>
              		<td className="text-value"> {row !== "" && getValue(row.ttv)}{row!=="" && row.ttvunit} {row === "" && "0"}</td>
              		<td className="text-value"> {row !== "" && getValue(row.mkcap)}{row!=="" && row.mkcapunit} {row === "" && "0"}</td>
              		<td style={{paddingRight:"10px"}}> 
                    <div style={{display: "flex", flexDirection: "column"}}>
                      <Button tag="a" width="60" color="primary" wideMobile style={{padding: "2px 2px", height:"28px"}} target="_blank" href={row !== "" && row.url}>Play Now</Button>
                      <div style={{height:"10px", width:"10px"}} />
                      <Button tag="a" color="primary" wideMobile style={{padding: "2px 2px", height:"28px"}} href="https://exchange.diamon.finance">Buy Token</Button>
                    </div>
                  </td>
                  
            	</tr>
            ))}
            
            <tr className="table-row">
              <td style={{padding: "0"}}><img src="/game/dms.png" alt="Game Defi" width="152px" /></td>
              <td className="text-value align-left"> Bit Monster NFT</td>
              <td className="text-value" colSpan="2"> Coming Soon</td>
              <td className="text-value" colSpan="2"> </td>
              <td style={{paddingRight:"10px"}}> 
                <div style={{display: "flex", flexDirection: "column"}}>
                  <Button tag="a" width="60" color="primary" wideMobile style={{padding: "2px 2px", height:"28px"}} target="_blank" href="https://www.bitmonsternft.com/">Goto website</Button>
                </div>
              </td>
            </tr>

            <tr className="table-row">
              <td style={{padding: "0"}}><img src="/game/ECQ.png" alt="Eclipse Quest" width="152px" /></td>
              <td className="text-value align-left"> Eclipse Quest</td>
              <td className="text-value" colSpan="2"> Coming Soon</td>
              <td className="text-value" colSpan="2"> </td>
              <td style={{paddingRight:"10px"}}> 
                <div style={{display: "flex", flexDirection: "column"}}>
                  <Button tag="a" width="60" color="primary" wideMobile style={{padding: "2px 2px", height:"28px"}} target="_blank" href="https://www.eclipsequest.bugbliostudio.com">Goto website</Button>
                </div>
              </td>
            </tr>

            <tr className="table-row">
              <td style={{padding: "0"}}><img src="/game/XRB.png" alt="XRB Galaxy Metaverse" width="152px" /></td>
              <td className="text-value align-left"> XRB Galaxy Metaverse</td>
              <td className="text-value" colSpan="2"> Coming Soon</td>
              <td className="text-value" colSpan="2"> </td>
              <td style={{paddingRight:"10px"}}> 
                <div style={{display: "flex", flexDirection: "column"}}>
                  <Button tag="a" width="60" color="primary" wideMobile style={{padding: "2px 2px", height:"28px"}} target="_blank" href="https://xrbgalaxy.com/">Goto website</Button>
                </div>
              </td>
            </tr>

            <tr>
              <td colSpan="7">
                <hr/>
              </td>
            </tr>

            <tr>
              <td className="text-value" style={{textAlign: "left"}}>DeFi Projects  </td>
              <td>   </td>
              <td colSpan="5"></td>
            </tr>

            <tr className="table-row">
              <td style={{padding: "0"}}><img src="/defi/megaland.png" alt="Project Defi" width="152px" /></td>
              <td className="text-value align-left"> Megaland</td>
              <td colSpan="2">Total NFTs Sold<br/><span className="text-value">{nftsum !==0 && getAmount(nftsum)}</span> items</td>
              <td colSpan="2">Total NFTs Volume<br/>
                <span className="text-value">
                  ฿{total!=="" && total.nft && total.nft.value}
                  {total!=="" && total.nft  && total.nft.unit}
                </span>
              </td>
              <td style={{paddingRight:"10px"}}> 
                <div style={{display: "flex", flexDirection: "column"}}>
                  <Button tag="a" width="60" color="primary" wideMobile style={{padding: "2px 2px", height:"28px"}} target="_blank" href="https://mega-land.io">Goto website</Button>
                </div>
              </td>
            </tr>


            { data!=="" && Array.isArray(data) && data.map((row, index)=>  (
              row.type === 'Defi' &&
              row.active === '1' && 
            <tr className="table-row" key={index}>
              <td style={{padding: "0"}}><img src="/defi/yuemmai.png" alt="Defi" width="152px" /></td>
              <td className="text-value align-left"> {row.name} <span className="badge">0.7x</span></td>
              <td>Price<br/><span className="text-value">฿18.12</span></td>
              <td>TVL<br/><span className="text-value">฿59.62M</span></td>
              <td>
                TTV<br/><span className="text-value">{getValue(row.ttv)}{row.ttvunit}</span>
              </td>
              <td>
                Market Cap<br/><span className="text-value">฿181.22M</span>
              </td>
              <td style={{paddingRight:"10px"}}> 
                <div style={{display: "flex", flexDirection: "column"}}>
                  <Button tag="a" width="60" color="primary" wideMobile style={{padding: "2px 2px", height:"28px"}} target="_blank" href="https://www.yestoken.digital">Goto website</Button>
                </div>
              </td>
            </tr>
            
            ))}

            { 2==1 && data!=="" && Array.isArray(data) && data.map((row, index)=>  (
              row.type === 'Defi' &&
              row.active === '1' && 
            <tr className="table-row" key={index}>
              <td style={{padding: "0"}}><img src="/defi/yuemmai.png" alt="Defi" width="152px" /></td>
              <td className="text-value align-left"> {row.name} <span className="badge">{calculateMulDefi(row.code, row.price)}</span></td>
              <td>Price<br/><span className="text-value">{getValue(row.price)}</span></td>
              <td>TVL<br/><span className="text-value">{getValue(row.tvl)}{row.tvlunit}</span></td>
              <td>
                TTV<br/><span className="text-value">{getValue(row.ttv)}{row.ttvunit}</span>
              </td>
              <td>
                Market Cap<br/><span className="text-value">{getValue(row.mkcap)}{row.mkcapunit}</span>
              </td>
              <td style={{paddingRight:"10px"}}> 
                <div style={{display: "flex", flexDirection: "column"}}>
                  <Button tag="a" width="60" color="primary" wideMobile style={{padding: "2px 2px", height:"28px"}} target="_blank" href="https://www.yestoken.digital">Goto website</Button>
                </div>
              </td>
            </tr>
            
            ))}
            
            <tr className="table-row">
              <td style={{padding: "0"}}><img src="/defi/loremboard.png" alt="Loremboard.finance" width="152px" /></td>
              <td className="text-value align-left"> Loremboard</td>
              <td className="text-value" colSpan="2"> Available Now</td>
              <td className="text-value" colSpan="2"> </td>
              <td style={{paddingRight:"10px"}}> 
                <div style={{display: "flex", flexDirection: "column"}}>
                  <Button tag="a" width="60" color="primary" wideMobile style={{padding: "2px 2px", height:"28px"}} target="_blank" href="https://loremboard.finance">Goto website</Button>
                </div>
              </td>
            </tr>

            <tr className="table-row">
              <td style={{padding: "0"}}><img src="/defi/freecity.png" alt="Free City" width="152px" style={{borderRadius:"40"}} /></td>
              <td className="text-value align-left"> Free City</td>
              <td className="text-value" colSpan="2"> Available Now</td>
              <td className="text-value" colSpan="2"> </td>
              <td style={{paddingRight:"10px"}}> 
                <div style={{display: "flex", flexDirection: "column"}}>
                  <Button tag="a" width="60" color="primary" wideMobile style={{padding: "2px 2px", height:"28px"}} target="_blank" href="https://freecity.finance/">Goto website</Button>
                </div>
              </td>
            </tr>

            <tr className="table-row">
              <td style={{padding: "0"}}><img src="/defi/bitpower.png" alt="Multiply" width="152px" /></td>
              <td className="text-value align-left"> Bitpower</td>
              <td className="text-value" colSpan="2"> Available on testnet</td>
              <td className="text-value" colSpan="2"> </td>
              <td style={{paddingRight:"10px"}}> 
                <div style={{display: "flex", flexDirection: "column"}}>
                  <Button tag="a" width="60" color="primary" wideMobile style={{padding: "2px 2px", height:"28px"}} target="_blank" href="https://ckt8712taz3f8giy.web.app/">Goto website</Button>
                </div>
              </td>
            </tr>

            <tr className="table-row">
              <td style={{padding: "0"}}><img src="/defi/Lorem.png" alt="KDEX" width="152px" /></td>
              <td className="text-value align-left"> KDEX</td>
              <td className="text-value" colSpan="2"> Coming Soon</td>
              <td className="text-value" colSpan="2"> </td>
              <td style={{paddingRight:"10px"}}> 
                <div style={{display: "flex", flexDirection: "column"}}>
                  <Button tag="a" width="60" color="primary" wideMobile style={{padding: "2px 2px", height:"28px"}} target="_blank" href="https://kdex.finance">Goto website</Button>
                </div>
              </td>
            </tr>
            <tr className="table-row">
              <td style={{padding: "0"}}><img src="/defi/pawnshop.png" style={{borderRadius: "7px"}} alt="Pawnshop" width="152px" /></td>
              <td className="text-value align-left"> Pawn Shop</td>
              <td className="text-value" colSpan="2"> Available on testnet</td>
              <td className="text-value" colSpan="2"> </td>
              <td style={{paddingRight:"10px"}}> 
                <div style={{display: "flex", flexDirection: "column"}}>
                  <Button tag="a" width="60" color="primary" wideMobile style={{padding: "2px 2px", height:"28px"}} target="_blank" href="https://pawns-finance.web.app/ ">Goto website</Button>
                </div>
              </td>
            </tr>

            
            </tbody>
          </table>

          
        </div>
        

      </div>
    </section>
  );
}


GameTable.propTypes = propTypes;
GameTable.defaultProps = defaultProps;

export default GameTable;

// <tr className="table-row">
//   <td style={{padding: "0"}}><img src="/game/dk.png" alt="Game Defi" width="147px" /> </td>
//   <td className="text-value align-left"> Dicekingdom</td>
//   <td className="text-value"> $0.45 </td>
//   <td className="text-value"> 1,603 M</td>
//   <td className="text-value"> 354.11M</td>
//   <td className="text-value"> 150M</td>
//   <td> <Button>Play</Button></td>
// </tr>
